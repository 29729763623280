<template>
  <div class="w-100 fixed">
    <Player360
      @tapped="writingDelay()"
      v-if="showVideo360 || showVideo"
      :url="video.stream_hls"
      :id="video.video_id"
      :cover="video.cover"
      :token="loginInfo.token"
      @exit="exit()"
      :options="[]"
      :showOptions="true"
      @handleVideo="() => {}"
      :type="video.video_type"
      :leftFix="true"
      :plain="showVideo"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { get } from 'lodash';
import Player360 from '@/components/Player360.vue';

export default {
  name: 'Play',
  components: {
    Player360,
  },
  data() {
    return {
      get,
      video: false,
      showVideo360: false,
      showVideo: false,
      rbv: false,
      interval: false,
      writing: false,
      writingTo: false,
    };
  },
  methods: {
    ...mapMutations(['appReady', 'setReferer', 'redirectToIP']),
    loadVideo(id) {
      const that = this;
      const ep = `/video/${id}`;
      this.api.get(ep, {
        headers: {
          'x-tok-token': get(this.loginInfo, 'token'),
          'Accept-Language': this.locale,
        },
      })
        .then((response) => {
          that.video = response.data;
          if (that.video.video_360 || that.video.title.includes('360')) {
            that.showVideo360 = true;
            that.showVideo = false;
          } else {
            that.showVideo360 = false;
            that.showVideo = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    console.log(this.loginInfo);
    if (!this.loginInfo) {
      const referrer = { path: 'play', query: { id: this.$route.query.id } };
      this.$ls.set('referrer', referrer, 10 * 1000);
      this.redirectToIP({
        router: this.$router,
        registered: false,
      });
    }
    this.appReady(true);
    this.loadVideo(this.$route.query.id);
  },
  computed: {
    ...mapState([
      'loginInfo',
      'locale',
      'api',
    ]),
  },
};
</script>

<style lang="scss" scoped>
  h1 {
    color: white;
  }
</style>
